
.containerss {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: -2px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.containerss input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 3px solid #2196f3;
    background-color: #c3c3c3;
}

/* On mouse-over, add a grey background color */
.containerss:hover input ~ .checkmark {
    border: 3px solid #2196f3;
    background-color: #c3c3c3;
}

/* When the checkbox is checked, add a blue background */
.containerss input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerss input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.containerss .checkmark:after {
    left: 7px;
    top: 1px;
    width: 7px;
    height: 15px;
    border: solid white;
    border-width: 0px 3px 3px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}